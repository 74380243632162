.pagina {
    width:100%;
    margin: -3% 10% 5% 10%;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.buscar{
    margin-top: 3%;
    margin-bottom:3%;
    width:65%;
    max-width: 800px;
    display: flex;
    flex-direction: row;
    align-items: center;

    background: #FFFFFF;
    border-radius: 8px;
}

.linha{
    display:flex;
    justify-content: space-between;
    align-items: center;
}


.espec{
    padding: 0px 2% 0px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:300px;
    height:50px;

    font-weight: 800;
    background-color: #003895;
    color:#FFFFFF;
    border-radius: 12px;
}

.add{
    padding: 0px 2% 0px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width:300px;
    height:50px;
    
    font-weight: 800;
    background-color:#FFFFFF;
    border: 2px solid #003895;
    border-radius: 8px;
    color:#003895;
    border-radius: 12px;

}

.usuarios {
    display:flex;
    flex-direction: row;
    align-items: center;
    width: auto;
}


.tabela{
    width:100%;
    height:auto;
    margin-top: 5%;
    margin-bottom:5%;
}

table{
    width:100%;
}

table, th, td {
    border-bottom:solid #003895 2px;
    border-collapse: collapse;
    height:60px;
    text-align: center;
}

td:first-child {
    width:10%;
  }
  td:nth-child(2) {
    width:30%;
  }
  td:nth-child(3) {
    width:20%;
  }
  td:nth-child(4) {
    width:20%;
  }
  td:last-child {
    width:20%;
  }


.headerTab {
    background-color: #003895;
    color:#FFFFFF;
}

.nUsuarios{
    width:120px;
    font-size: 16px;
}


