.pagina {
    width: 90%;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pagina .voltar {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.buscar {
    position: relative;
    height: 100%;
    width: 50%;
    max-width: 800px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #FFFFFF;
    border-radius: 8px;
}

.linha {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1vh;
}

.espec {
    padding: 0px 2% 0px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    height: 50px;

    font-weight: 800;
    background-color: #003895;
    color: #FFFFFF;
    border-radius: 12px;
}

.add {
    padding: 0px 2% 0px 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 300px;
    height: 50px;

    font-weight: 800;
    background-color: #FFFFFF;
    border: 2px solid #003895;
    border-radius: 8px;
    color: #003895;
    border-radius: 12px;

}

.usuarios {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
}


.tabelaConsultas {
    margin-top: 1vh;
    width: 100%;
    height: auto;
}

.tabelaConsultas table {
    width: 100%;
}

.tabelaConsultas thead {
    display: block;
}

.tabelaConsultas tbody{
    height: calc(5*66px);
    display: block;
    overflow-y: scroll;   
    overflow-x: hidden;  
}

.tabelaConsultas table,
.tabelaConsultas th,
.tabelaConsultas td {
    width: 100%;
    border-bottom: solid #003895 2px;
    border-collapse: collapse;
    height: 60px;
    text-align: center;
    padding: 10px;
}

.tabelaConsultas td:first-child {
    width: 7%;
}

.tabelaConsultas td:nth-child(2) {
    width: 25%;
}

.tabelaConsultas td:nth-child(3) {
    width: 20%;
}

.tabelaConsultas td:nth-child(4) {
    width: 7%;
}

.tabelaConsultas td:nth-child(5) {
    width: 10%;
}

.tabelaConsultas td:nth-child(6) {
    width: 10%;
}

.headerTab {
    background-color: #003895;
    color: #FFFFFF;
}

.headerTab th:first-child {
    width: 7%;
}

.headerTab th:nth-child(2) {
    width: 25%;
}

.headerTab th:nth-child(3) {
    width: 20%;
}

.headerTab th:nth-child(4) {
    width: 7%;
}

.headerTab th:nth-child(5) {
    width: 10%;
}

.headerTab th:nth-child(6) {
    width: 10%;
}

.nUsuarios {
    width: 120px;
    font-size: 16px;
}