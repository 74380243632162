.accordion{
    margin:2% 0% 2% 0%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.titulo{
    font-weight: 800;
    font-size: 20px;
    font-family: 'Mulish';
}

.subtitulo{
    font-weight: 800;
    font-size: 16px;
    margin-bottom:2%;
    font-family: 'Mulish';
}

.section{
    font-weight: 800;
    font-size: 20px;
    font-family: 'Mulish';
}