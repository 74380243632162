.box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 16px;
    background: #E5E9FF;
    border-radius: 12px;
    width: auto;
    min-width: 336px;
    height: 96px;
    flex: none;
    order: 0;
    flex-grow: 1;
    overflow: hidden;
}



.box .infos {
    display: flex;
    flex-direction: column;
    
}

.box .infos .name{
    font-weight: bold;
    padding-left: 16px;
    /* font-size: 100px; */
}

.box .infos .ocupacao {
    padding-left: 16px;
    padding-top: 4px;
}

.box .imagem {
    width: 72px;
    height: 72px;
}

.box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

@media screen and (max-width: 1024px){
    .box {
        display: flex;
        flex-direction: row;
        background: #d2d8fe;
        border-radius: 20px;
        min-width: 70vw;
        flex: none;
        order: 0;
        flex-grow: 1;
        align-items: center;
    }

    .box .infos {
        display: flex;
        flex-direction: column;
    }
    
    .box .infos .name{
        font-weight: bold;
        padding-left: 3vw;
        font-size: 1.5rem;
    }
    
    .box .infos .ocupacao {
        padding-left: 3vw;
        padding-top: 1vh;
        font-size: 1.5rem;
    }
    
    .box .imagem {
        width: 5vw;
        height: 5vh;
    }
    
    .box img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
}