@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:wght@400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300&display=swap');

.mainBox {
    position: relative;
    background: linear-gradient(57.12deg, rgba(0, 56, 149, 0.9) 25.84%, rgba(235, 183, 12, 0.9) 95.25%);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.menu {
    /*Menu em coluna na esquerda da página*/

    width: 20%;
    height: 100%;
    padding-top: 5%;

    background: #FFFFFF;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left bottom;

    backdrop-filter: blur(18px);
    display: flex;
    flex-direction: column;

    border-radius: 0px;
}

.menu .linkMenu {
    display: flex;
    align-items: flex-start;
    margin-left: 30%;
    margin-top: 10%;
    cursor: pointer;
}

.menu .textoMenu {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
}

.menu .linkMenu .imgMenu {
    width: 10px;
    height: 10px;
}

.profileBox {
    position: relative;
    background: #ffffff;
    width: 95%;
    height: 52%;
    padding: 3% 5%;
    border-radius: 1rem;
    overflow: auto;

}

.profileBox h3 {
    font-family: Mulish;
    font-size: 1.5rem;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
}

button {
    font-family: Mulish;
    font-size: 1rem;
    font-weight: 800;
    line-height: 1.5em;
    letter-spacing: 0em;
    text-align: left;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
}

button span {
    margin-right: 0.5rem;
}

.formBox {
    display: flex;
    flex-direction: column;
    width: 80%;
}

h4 {
    font-size: 1rem;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    height: 100%;
    gap: 1rem;
    margin-right: 1rem;
}

.topicBox {
    width: 100%;
    height:50%;
}

.topicBox h3 {
    font-family: Mulish;
    font-size: max(1vw, 0.8rem);
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    overflow-x: clip;
    overflow-y: visible;
    white-space: nowrap;
    height: 80%;
    width: 100%;
}

/* Estilização específica da barra de rolagem para navegadores WebKit (Chrome, Safari) */
.elemento-com-overflow::-webkit-scrollbar {
    width: 0; 
    height: 0;
}

.topicBox h4 {
    font-family: Mulish;
    font-size: 1rem;
    font-weight: 400;
    line-height: 0.4rem;
    letter-spacing: 0em;
    text-align: left;
}


.screenMobile {
    width: 100vw;
}

.backButtonMobile {
    margin-left: 6.5vw;
}

.profileMobile {
    margin-left: 6.5vw;
    padding-top: 2rem;
    text-align: center;
    border-radius: 24px;
    width: 87vw;
    background-color:#E5E9FF;
    margin-bottom: 1rem;
    overflow: auto;
}

.planMobile {
    margin-left: 6.5vw;
    text-align: center;
    border-radius: 24px;
    width: 87vw;
    background-color:#E5E9FF;
    margin-bottom: 2rem;
    overflow: auto;
}

.backButtonMobile{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.linkVoltarMobile {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 3vh 0vw 2vh 6vw;
    font-weight: 700;
    font-size: 2vw;
    line-height: 2vh;
}

.editButtonMobile {
    background-color: #003895;
    color: #FFFFFF;
    width: 76vw;
    height: 2rem;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 2rem;

}

.profileInformationMobile {
    text-align: left;
    padding: 1rem;
}

.editProfileInformationMobile {
    text-align: left;
    padding: 1.5rem;
}


.saveButtonMobile {
    background-color: #003895;
    color: #FFFFFF;
    width: 76vw;
    height: 2rem;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 1rem;

}

.cancelButtonMobile {
    margin-bottom: 2rem;
}