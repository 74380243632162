.popUpBackground{
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 200, 0.5);
    position: fixed;
    display: flex;
    top: 0;
    justify-content: center;
    align-items: center;
}

.container{
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}