.link {
  border-bottom: #fff 3px solid;
}

.link:hover {
  border-bottom: #ebb70c 3px solid;
}

.label {
  margin-top: 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 0.5rem;
}

table,
th,
td {
  border-bottom: 2px solid #003895;
  border-collapse: collapse;
  /* height: 60px; */
  text-align: center;
}

td:first-child {
  width: 40%;
}

td:nth-child(2) {
  width: 30%;
}


td:last-child {
  width: 20%;
  padding: 0;
} 

.headerTab {
  background-color: #003895;
  color: #ffffff;
}