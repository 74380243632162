@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&family=Roboto:wght@100;700&display=swap');

*{
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url(../../../public/cornerDetail.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 70vh;
    width: 100vw;
}

.contentCard{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 73.5vh;
    width: 70%;
    margin-bottom: 10vh;

    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 24px;

    max-width: 1100px;
}

.textFieldSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    width: 50%;
    height: 100%;
    padding: 7vh 10vh;

    background: #E5E9FF;;
    border-radius: 24px 0px 0px 24px;
}

.textFieldSection h2{
    font-style: normal;
    font-weight: 700;
    font-size: 2.2em;
    line-height: 40px;
    margin-bottom: 20px;
}

.textField{
    width: 100%;
    background-color: #CDD3FF;
    
    border: none;
    border-bottom: 2px solid #606060;
    border-radius: 8px;
}

.btn{
    width: 100%;

    background: #003895;
    border-radius: 12px;
    border: none;

    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #FFFFFF;

    padding: 13px;
    margin-top: 20px;
    margin-bottom: 70px;

    cursor: pointer;
}













.contentMobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url(../../../public/cornerDetail.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 70vh;
    width: 100vw;
}

.contentCardMobile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 73.5vh;
    width: 80vw;
    margin-bottom: 10vh;

    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 2vh;

    max-width: 1100px;
}

.textFieldSectionMobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    width: 80vw;
    height: 100%;
    padding: 5vh 8vw;

    background: #E5E9FF;;
    border-radius: 2vh;
}

.textFieldSectionMobile h2{
    font-style: normal;
    font-weight: 700;
    font-size: 2.2em;
    line-height: 40px;
    margin-bottom: 5vh;
}

.textFieldMobile{
    width: 100%;
    background-color: #CDD3FF;
    
    border: none;
    border-bottom: 2px solid #606060;
    border-radius: 8px;
}

.btnMobile{
    width: 100%;

    background: #003895;
    border-radius: 12px;
    border: none;

    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #FFFFFF;

    padding: 13px;
    margin-top: 20px;
    margin-bottom: 70px;

    cursor: pointer;
}