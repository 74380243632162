.pagina {
    width:100vw;
    height:calc(100vh - 4rem);
    display:flex;
    margin:0;
    padding:0;
    box-sizing: border-box;
}

.menu {
    width: 20%;
    height: 100%;
    padding-top:2%;
    
    background:#FFFFFF;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left bottom;
    
    backdrop-filter: blur(18px);
    display: flex;
    flex-direction: column;
    
    border-radius: 0px;

}

.menu .linkMenu {
    display:flex;
    align-items: flex-start;
    margin-left:10%;
    margin-top:10%;
    cursor: pointer;
    gap: 6px;
}

.menu .textoMenu {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
}

.menu .textoMenu:hover {
    text-decoration: underline 1px black;
}

.menu .linkMenu .imgMenu{
    width:10px;
    height:10px;
}


.imagemBackground {
    width:100%;
    background: linear-gradient(57.12deg, rgba(0, 56, 149, 0.9) 25.84%, rgba(235, 183, 12, 0.9) 95.25%);
    display:flex;
    align-items: center;
    justify-content: center;
    padding:30px;
}

.tela {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 10px 10px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    overflow: auto;
    width:100%;
    height:100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 33px;
    box-sizing: border-box;
    font-family: 'Mulish';
    font-style: normal;
}

.linkVoltar {
    display: flex;
    align-items: center;
    cursor: pointer;

    margin: 30px 0px 0px 27px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.linkVoltar .textoMenu:hover{
    text-decoration: underline rgba(235, 183, 12, 0.9) 3px;
}


.linkVoltar .textoMenu{
    font-size: 20px;
}


.linhaCuidado{
    display: flex;
    margin: 50px 0px 0px 27px;
    font-weight: 800;
    font-size: 32px;
}

.linhaCuidado .textoCuidado{
    padding-left: 7px;
    color: rgb(14, 14, 161);
}


.textoProgresso{
    margin-left: 27px;
    margin-top: 4vh;
    display: flex;
    font-size:22px;
    margin-bottom: 2vh;

    text-decoration: underline rgba(235, 183, 12, 0.9) 3px;
}


.listaConsultas{
    display: flex;
    flex-direction: column;
    margin: 0px 27px;
}

.listaConsultas .consulta{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
    padding:3vh;
    margin-top: 1vh;
} 

.listaConsultas .informacoesConsulta {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.consulta .consultaVerDetalhes {
    text-decoration: underline rgb(11, 11, 188) 1.5px;
    color: rgb(11, 11, 188);

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;

    cursor: pointer;

    background-color: #FFFFFF;
    border: none;
}

.consulta .consultaVerDetalhes:hover{
    text-decoration: underline rgb(6, 6, 80) 1.5px;
    color: rgb(6, 6, 80);
}

.barra{
    padding: 6px;
    font-size: 24px;
    font-weight:500;
}

.linha {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 30px 0px 25px;
}

.paginaMobile{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2vh;
    padding: 6.67vw 6.67vw 6.67vw 5.77vw;     
}

.linkVoltarMobile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.paginaContentBackgroundMobile {
    display: flex;
    width: 86.7vw;
    padding: 8.88vw 6.67vw;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
    border-radius: 6.67vw;
    background: #E5E9FF
}

.paginaContentMobile {
    display: flex;
    width: 73.326vw;
    flex-direction: column;
    align-items: flex-start;
    gap: 3vh;
}

.linhaCuidadoMobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4vh;
    align-self: stretch;
    color: black;
    text-align: center;
    font-family: Mulish;
    font-size: 6.67vw;
    font-style: normal;
    font-weight: 800;
    line-height: 2.5vh;
}

.textoCuidadoMobile{
    color: var(--primary-primary-01, #003895);
    font-family: Mulish;
    font-size: 6.67vw;
    font-style: normal;
    font-weight: 800;
    line-height: 2.5vh;
}

.linhaConsultasMobile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4vh;
    align-self: stretch;
}

.consultaMobile{
    display: flex;
    width: 73.3vw;
    padding: 3.3vw 4.4vw;
    gap: 1vh;
    border-radius: 2.22vw;
    background: #FFF;
    box-shadow: 0 0 3vw 0 rgba(0, 0, 0, 0.25);
    margin: 5vw 0;
}

.informacoesConsultaMobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
    flex: 1 0 0;
    align-self: stretch;
}

.consultaDoutorMobile {
    align-self: stretch;
    color: #000;
    font-family: Mulish;
    font-size: 4.44vw;
    font-style: normal;
    font-weight: 800;
    line-height: 4.70vw; 
}

.consultaDataStatusMobile{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.8vh;
}

.consultaDataMobile{
    color: var(--primary-primary-01, #003895);
    font-family: Mulish;
    font-size: 4.44vw;
    font-style: normal;
    font-weight: 800;
    line-height: 2vh; 
}

.barraMobile{
    width: 0.55vw;
    height: 8vw;
    border-radius: 2.22vw;
    background: var(--neutral-900, #393939);
}

.consultaStatusMobile{
    font-family: Mulish;
    font-size: 4.44vw;
    font-style: normal;
    font-weight: 800;
    line-height: 2vh;
}

.consultaVerDetalhesMobile {
    color: var(--primary-primary-01, #003895);
    font-family: Mulish;
    font-size: 4.44vw;
    font-style: normal;
    font-weight: 800;
    line-height: 2vh; 
    text-decoration-line: underline;
}
@media screen and (max-width: 767px) {
    
    .pagina {
        width:100vw;
        height:auto;
        display:flex;
        margin:0;
        padding-left: 10vw;
        padding-right: 10vw;
        box-sizing: border-box;
        font-size: 1rem;
    } 

    .tela {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 5vh 5vw 0 5vw;
        font-size: 3vw;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 800;
        padding: 0 0 4vh 0;
        width:80vw;
        margin-left: 10vw;
        margin-right: 10vw;
        height:auto;
        background: #e5e9ff;
        box-shadow: 0px 2vh 2vh rgba(0, 0, 0, 0.25);
        border-radius: 6vw;
        box-sizing: border-box;
        font-family: 'Mulish';
        font-style: normal;
        margin-bottom: 30vh;
    }
    
    .linkVoltar {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 4vh 0vw 0px 10vw;
        font-weight: 700;
        font-size: 2vw;
        line-height: 2vh;
    }
    
    .linkVoltar .textoMenu:hover{
        text-decoration: underline rgba(235, 183, 12, 0.9) 3px;
    }
    
    
    .linkVoltar .textoMenu{
        font-size: 2.5vh;
    }
    
    .image {
        color: #e5e9ff;
        margin-top: 6vh;
        padding-left: 50%;
        padding-right: 50%;
        min-height: 79px;
        min-width: 95px;
        position: center;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.4;
    }
    .linhaCuidado{
        line-height: 0;
        margin: auto;
        padding: 2.5vh 2vw ;
        display: flex;
        margin: 2.5vh 0px 0px 0vh;
        font-weight: 800;
        font-size: 1.4rem;
        align-items: center;
    }
    
    .linhaCuidado .textoCuidado{
        align-items: center;
        color: rgb(14, 14, 161);
    }
    
    
    .textoProgresso{
        margin-left: 27px;
        margin-top: 4vh;
        display: flex;
        font-size:22px;
        margin-bottom: 2vh;
    
        text-decoration: underline rgba(235, 183, 12, 0.9) 3px;
    }
    
    
    .listaConsultas{
        display: flex;
        flex-direction: column;
        margin: 1vh 2vw;
        font-size: 1rem;
    }
    
    .listaConsultas .consulta{
        max-width: 70vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        background-color: #FFFFFF;
        border-radius: 0.5vh;
        box-shadow: 0px 0.4vh 1.7vh rgba(0, 0, 0, 0.5);
        padding:1.5vh 10vw;
        margin-top: 3.5vh;
    } 
    
    .listaConsultas .informacoesConsulta {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1rem;
    }
    .listaConsultas .informacoesConsulta .line {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2vh 0;
    }
    
    .consulta .informacoesConsulta .consultaVerDetalhes {
        margin: 0;
        padding:0  ;
        display: flex;
        margin: 0vh 0px 0px 0vh;
        text-decoration: underline rgb(11, 11, 188) 1.5px;
        color: rgb(11, 11, 188);
    
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 800;
    
        cursor: pointer;
        align-items: center;
        background-color: #FFFFFF;
        border: none;
    }
    
    .consulta .consultaVerDetalhes:hover{
        text-decoration: underline rgb(6, 6, 80) 1.5px;
        color: rgb(6, 6, 80);
    }
    
    .barra{
        padding: 6px;
        font-size: 24px;
        font-weight:500;
    }
    
    .linha {
        display: flex;   
        justify-content: space-between;
        align-items: center;
        margin: 2vh 0vw;
    }   
  }

  @media screen and (min-width:768px) and  (max-width: 1024px) {  
    
    .pagina {
        width:100vw;
        height:auto;
        display:flex;
        margin:0;
        padding-left: 10vw;
        padding-right: 10vw;
        box-sizing: border-box;
        font-size: 16px;
    } 
    
    .tela {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 5vh 5vw 0 5vw;
            font-size: 3vw;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 800;
            padding: 0 0 4vh 0;
            width:80vw;
            margin-left: 10vw;
            margin-right: 10vw;
            height:auto;
            background: #e5e9ff;
            box-shadow: 0px 2vh 2vh rgba(0, 0, 0, 0.25);
            border-radius: 6vw;
            box-sizing: border-box;
            font-family: 'Mulish';
            font-style: normal;
            margin-bottom: 30vh;
        }
        
        .linkVoltar {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 4vh 0vw 0px 10vw;
            font-weight: 700;
            font-size: 2vw;
            line-height: 2vh;
        }
        
        .linkVoltar .textoMenu:hover{
            text-decoration: underline rgba(235, 183, 12, 0.9) 3px;
        }
        
        
        .linkVoltar .textoMenu{
            font-size: 2.5vh;
        }
        
        .image {
            color: #e5e9ff;
            margin-top: 6vh;
            padding-left: 50%;
            padding-right: 50%;
            min-height: 79px;
            min-width: 95px;
            position: center;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0.4;
        }
        .linhaCuidado{
            line-height: 0;
            margin: auto;
            padding: 2.5vh 2vw ;
            display: flex;
            margin: 2.5vh 0px 0px 0vh;
            font-weight: 800;
            font-size: 1.4rem;
            align-items: center;
        }
        
        .linhaCuidado .textoCuidado{
            align-items: center;
            color: rgb(14, 14, 161);
        }
        
        
        .textoProgresso{
            margin-left: 27px;
            margin-top: 4vh;
            display: flex;
            font-size:22px;
            margin-bottom: 2vh;
        
            text-decoration: underline rgba(235, 183, 12, 0.9) 3px;
        }
        
        
        .listaConsultas{
            display: flex;
            flex-direction: column;
            margin: 1vh 2vw;
            font-size: 1.5rem;
        }
        
        .listaConsultas .consulta{
            max-width: 70vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
            background-color: #FFFFFF;
            border-radius: 0.5vh;
            box-shadow: 0px 0.4vh 1.7vh rgba(0, 0, 0, 0.5);
            padding:1.5vh 10vw;
            margin-top: 3.5vh;
        } 
        
        .listaConsultas .informacoesConsulta {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 1.5rem;
        }
        .listaConsultas .informacoesConsulta .line {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 2vh 0;
        }
        
        .consulta .informacoesConsulta .consultaVerDetalhes {
            margin: 0;
            padding:0  ;
            display: flex;
            margin: 0vh 0px 0px 0vh;
            text-decoration: underline rgb(11, 11, 188) 1.5px;
            color: rgb(11, 11, 188);
            font-size: 1.5rem;
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 800;
        
            cursor: pointer;
            align-items: center;
            background-color: #FFFFFF;
            border: none;
        }
        
        .consulta .consultaVerDetalhes:hover{
            text-decoration: underline rgb(6, 6, 80) 1.5px;
            color: rgb(6, 6, 80);
        }
        
        .barra{
            padding: 6px;
            font-size: 24px;
            font-weight:500;
        }
        
        .linha {
            display: flex;   
            justify-content: space-between;
            align-items: center;
            margin: 2vh 0vw;
        }  
  }
