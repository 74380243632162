.pagina {
  width: 100%;
  height: 100%;
}


.pagina .voltar {
  margin: 50px 0px 0px 30px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.pagina .barraLinks {
  margin: 50px 0px 40px 30px;
  display: flex;
}

.pagina .barraLinks .linhaLinks {
  width: 2px;
  height: 30px;
  margin: 0px 20px 0px 20px;
  background-color: black;
}

.pagina .barraLinks .link {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  margin: 0px 0px 0px 0px;
}

.link:hover {
  border-bottom: #ebb70c 3px solid;
}

.link {
  border-bottom: #fff 3px solid;
}
.pagina .conteudo {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pagina .conteudo .dados {
  width: 100%;
}

.pagina .conteudo .dados .linha {
  justify-content: space-between;
  display: flex;
  justify-content: space-around;
}

.pagina .conteudo .dados .botao {
  position: absolute;
  right: 0px;
  margin: 20px 30px 20px 30px;
}
