.tabela {
  width: 100%;
  height: auto;
  margin-top: 5%;
  margin-bottom: 1%;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
}

table {
  width: 100%;
}

table,
th,
td {
  border-bottom: solid #003895 2px;
  border-collapse: collapse;
  height: 60px;
  text-align: center;
}

td:first-child {
  width: 100%;
}

td:nth-child(2) {
  width: 0%;
}

td:nth-child(3) {
  width: 40%;
}

td:last-child {
  width: 10%;
}

.headerTab {
  background-color: #003895;
  color: #ffffff;
}
