.pagInicial {
    width: 100%;
    height: calc(100vh - 4rem);
    display: flex;

}


.menu {
    /*Menu em coluna na esquerda da página*/

    width: 20%;
    height: 100%;
    padding-top: 2%;

    background: #FFFFFF;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left bottom;

    backdrop-filter: blur(18px);
    display: flex;
    flex-direction: column;

    border-radius: 0px;
}

.menu .linkMenu {
    display: flex;
    align-items: flex-start;
    margin-left: 10%;
    margin-top: 10%;
    cursor: pointer;
    gap: 6px;
}

.menu .linkMenu:hover {
    text-decoration: underline;

}

.menu .textoMenu {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 1.5rem;
}

.menu .linkMenu .imgMenu {
    width: 0.625rem;
    height: 0.625rem;
}

.tela {
    width: 100%;
    background: linear-gradient(57.12deg, rgba(0, 56, 149, 0.9) 25.84%, rgba(235, 183, 12, 0.9) 95.25%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.875rem;
}

.tela .card {
    width: 100%;
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2.0625rem;
    box-sizing: border-box;
    font-family: 'Mulish';
    font-style: normal;
    overflow: auto;
}

.tela .card .topoCard {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.tela .card .topoCard .saudacao {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.tela .card .topoCard .saudacao .title {
    font-weight: 800;
    font-size: 2rem;
    line-height: 2.3rem;
}

.tela .card .topoCard .saudacao .subtitle {
    font-weight: 800;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #B0B0B0;
}

.tela .card .topoCard .notificacao {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 0;
    width: 40%;
    height: 10vh;
    padding: 1.5rem 0 1.5rem 1rem;
    background: #E5E9FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2.0625rem;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 1.5rem;
    line-height: 2.4rem;
}


.tela .card .meioCard {
    width: 100%;
    height: 90%;
    display: flex;
    color: #FFFFFF;
    font-size: 1.25rem;
    font-weight: 800;
}


.tela .card .meioCard .numeroEsq {
    font-size: 3.25rem;
}

.tela .card .meioCard .numeroDir {
    font-size: 3.25rem;
}


.tela .card .meioCard .left {
    width: 75%;
    height: 100%;
    display: grid;
    grid-template-rows: 35% 34% 26%;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 3%;
    grid-row-gap: 3%;
    padding: 1.8% 2% 1.5% 2%;
    font-size: 2.5vh;
}

.tela .card .meioCard .left .aps {
    color: #003895;
}

.tela .card .meioCard .left .plus {
    color: #FFC300;
}

.notificacao {
    cursor: pointer;
}

.tela .card .meioCard .left .consultasMarcadas{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    cursor: pointer;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: right top;

    background-color: #03CEA4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 33px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;
}

.card .meioCard .textConsultaMarcada{
    text-align: center;
    justify-content: center;
    padding-bottom: 10;
}

.tela .card .meioCard .left .novaConsulta {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;

    background-color: #5C80FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: right top;

    padding: 5%;
    border-radius: 2.0625rem;
    display: flex;
    align-items: flex-end;
}

.tela .card .meioCard .left .ultimaConsulta {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    cursor: pointer;
    background-color: #2EBFDF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right top;

    padding: 5%;
    border-radius: 2.0625rem;
}

.tela .card .meioCard .left .equipeSaude {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    cursor: pointer;
    background-color: #F04379;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: right top;

    padding: 5%;
    border-radius: 33px;
}

.tela .card .meioCard .left .equipeSaude p {
    position: relative;
    margin-top: 40%;
    margin-left: 10%;
}

.tela .card .meioCard .left .anexoExame {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;

    background-color: #7C51F5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right top;

    padding: 5%;
    border-radius: 33px;
}

.tela .card .meioCard .left .anexoExame:hover {
    cursor: pointer;
}

.tela .card .meioCard .left .anexoExame p {
    margin-top: 40%;
    margin-left: 10%;
}


.tela .card .meioCard .left .perfil {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;

    background-color: #3494F3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: right bottom;

    padding-left: 5%;
    border-radius: 33px;
    padding-top: 10%;
    font-size: 2.2rem;

}

.tela .card .meioCard .left .perfil:hover {
    cursor: pointer;
}

.tela .card .meioCard .left .perfil p span {
    text-align: end;
    margin-left: 30%;
    font-size: 3rem;
}

.tela .card .meioCard .left .perfil p progress {
    appearance: none;
}

.tela .card .meioCard .left .perfil p progress::-webkit-progress-bar {
    background-color: lightgray;
}

/* Estilizando a parte preenchida da barra de progresso */
.tela .card .meioCard .left .perfil p progress::-webkit-progress-value {
    background-color: #FFC300;
}

.tela .card .meioCard .left .perfil p .progresscontainer {
    width: 70%;
    height: 10px;
    /* Defina a altura da barra */
    background-color: #FFC300;
    border-radius: 6px;
    /* Arredonde as bordas do contêiner */
    overflow: hidden;
}

.tela .card .meioCard .left .consultasCompletas {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4;
    background-color: #FF454F;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: right top;
    padding-left: 5%;
    padding-top: 2%;
    border-radius: 33px;
}

.tela .card .meioCard .left .consultasCompletas:hover {
    cursor: pointer;
}

.tela .card .meioCard .left .consultasCompletas p span {
    text-align: end;
    margin-left: 40%;
    font-size: 3rem;
}

.tela .card .meioCard .left .consultasCompletas p .progresscontainer {
    width: 75%;
    height: 12px;
    background-color: #03CEA4;
    border-radius: 6px;
    overflow: hidden;
}

.tela .card .meioCard .left .consultasCompletas p progress {
    height: 100%;
}

.tela .card .meioCard .left .consultasCompletas p progress::-webkit-progress-bar {
    background-color: lightgray;
}

/* Estilizando a parte preenchida da barra de progresso */
.tela .card .meioCard .left .consultasCompletas p progress::-webkit-progress-value {
    background-color: #03CEA4;
}

.tela .card .meioCard .right {
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.8% 2% 1% 1%;

}

.tela .card .meioCard .right .ultimoProf {
    width: 100%;
    height: 57%;
    background-color: #003895;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10%;
    margin-bottom: 30px;
    border-radius: 33px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.tela .card .meioCard .right .ultimoProf .topo {
    width: 80%;
    height: max-content;
    text-align: center;
    font-size: 0.9rem;

}

.tela .card .meioCard .right .ultimoProf .imagem {
    width: 80%;
    height: 80%;

}



.tela .card .meioCard .right .numeroConsultas {

    width: 100%;
    height: 45%;
    cursor: pointer;
    background-color: #FFC400;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: right bottom;

    padding: 12%;
    border-radius: 2.0625rem;
}




.telaMobile {
    width: 100%;
}

.topoCardMobile {
    width: 100%;
    background: linear-gradient(57.12deg, rgba(0, 56, 149, 0.9) 25.84%, rgba(235, 183, 12, 0.9) 95.25%);
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 7%;
    border-radius: 33px;
}

.telaMobile .cardMobile {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 0%;
    background: #FFFFFF;
    box-sizing: border-box;
    font-family: 'Mulish';
    font-style: normal;

    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;

}

.topoCardMobile {
    margin-left: 10%;
}

.telaMobile .cardMobile .topoCardMobile {
    width: 80vw;
    /* height: 20vh; */
    justify-content: center;
    align-items: center;
}

.telaMobile .cardMobile .topoCardMobile .saudacaoMobile {
    display: flex;
    flex-direction: column;
    justify-content: top;
}

.telaMobile .cardMobile .topoCardMobile .saudacaoMobile .titleMobile {
    font-weight: 800;
    font-size: 5vw;
    line-height: 2vh;
    color: #FFFFFF;
}

.telaMobile .cardMobile .topoCardMobile .saudacaoMobile .titleMobile p {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1%;
}

.telaMobile .cardMobile .topoCardMobile .saudacaoMobile .subtitleMobile {
    font-weight: 500;
    font-size: 3vw;
    line-height: 3.1vw;
    color: #FFFFFF;
    margin: 1vh;
}

.telaMobile .cardMobile .topoCardMobile .notificacaoMobile {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 1vh auto;
    width: 50vw;
    height: 4vh;
    padding: 6%;
    background: #E5E9FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 2.3vw;
    line-height: 1.5vh;
}


.telaMobile .cardMobile .meioCardMobile {
    width: 100%;
    height: auto;
    display: flex;
    color: #FFFFFF;
    font-size: 1.5vh;
    font-weight: 800;
}

.telaMobile .cardMobile .ultimoCardMobile {
    color: #FFFFFF;
    font-size: 1.5rem;
    font-weight: 800;
}

.telaMobile .cardMobile .meioCardMobile .numeroEsqMobile {
    font-size: 4rem;
    line-height: 4rem;
    /* margin-bottom: 1rem; */
}

.telaMobile .cardMobile .ultimoCardMobile .numeroEsqMobile {
    font-size: 4rem;
}

.telaMobile .cardMobile .meioCardMobile .numeroDirMobile {
    font-size: 4rem;
}

.telaMobile .cardMobile .ultimoCardMobile .numeroDirMobile {
    font-size: 4rem;
}



.telaMobile .cardMobile .meioCardMobile .leftMobile {
    width: 55vw;
    height: auto;
    margin-left: 8%;
    display: flex;
    flex-direction: column;
    padding: 2%;
    gap: 1rem;
}

.telaMobile .cardMobile .ultimoCardMobile .apsMobile {
    color: #003895;
}

.telaMobile .cardMobile .ultimoCardMobile .plusMobile {
    color: #FFC300;
}

.notificacaoMobile {
    cursor: pointer;
}

.telaMobile .cardMobile .meioCardMobile .leftMobile .consultasMarcadasMobile {
    cursor: pointer;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: right top;

    background-color: #03CEA4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 33px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    height: 26vh;
    width: 38vw;
    padding: 5vw;
    font-size: 5vw;
}

.telaMobile .cardMobile .meioCardMobile .leftMobile .ultimaConsultaMobile {
    cursor: pointer;
    background-color: #2EBFDF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: right top;

    height: 23vh;
    width: 38vw;
    border-radius: 33px;
    padding: 3.7vw;
    font-size: 1.8vh;
}

.telaMobile .cardMobile .meioCardMobile .leftMobile .ultimaConsultaMobile p {
    width: 1vw;
}

.telaMobile .cardMobile .ultimoCardMobile .equipeSaudeMobile {
    cursor: pointer;
    background-color: #F04379;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: right top;

    border-radius: 33px;
}

.telaMobile .cardMobile .ultimoCardMobile .equipeSaudeMobile p {
    font-size: 2.4vh;
    margin-top: 6vh;
    width: 21vw;
}

.telaMobile .cardMobile .ultimoCardMobile .anexoExameMobile {
    background-color: #7C51F5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right top;

    padding: 5%;
    border-radius: 33px;
}

.telaMobile .cardMobile .ultimoCardMobile .anexoExameMobile:hover {
    cursor: pointer;
}

.telaMobile .cardMobile .ultimoCardMobile .perfilMobile {
    background-color: #3494F3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: right bottom;

    padding-left: 5%;
    border-radius: 33px;
    padding-top: 10%;
    font-size: 3rem;

    height: 15vh;
    width: 80vw;
}

.telaMobile .cardMobile .ultimoCardMobile .perfilMobile:hover {
    cursor: pointer;
}

.telaMobile .cardMobile .ultimoCardMobile .perfilMobile p span {
    text-align: end;
    margin-left: 30%;
    font-size: 2rem;
}

.telaMobile .cardMobile .ultimoCardMobile .perfilMobile p progress {
    appearance: none;
}

.telaMobile .cardMobile .ultimoCardMobile .perfilMobile p progress::-webkit-progress-bar {
    background-color: lightgray;
}

/* Estilizando a parte preenchida da barra de progresso */
.telaMobile .cardMobile .ultimoCardMobile .perfilMobile p progress::-webkit-progress-value {
    background-color: #FFC300;
}

.telaMobile .cardMobile .ultimoCardMobile .perfilMobile p .progresscontainerMobile {
    width: 70%;
    height: 10px;
    /* Defina a altura da barra */
    background-color: #FFC300;
    border-radius: 6px;
    /* Arredonde as bordas do contêiner */
    overflow: hidden;
}

.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile {
    background-color: #FF454F;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: right top;
    padding: 5%;
    border-radius: 33px;
}

.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile:hover {
    cursor: pointer;
}

.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile p .progresscontainerMobile {
    width: 75%;
    height: 12px;
    background-color: #03CEA4;
    border-radius: 6px;
    overflow: hidden;
}

.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile p {
    font-size: 2.2vh;
}

.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile p progress {
    height: 100%;
}

.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile p progress::-webkit-progress-bar {
    background-color: lightgray;
}

/* Estilizando a parte preenchida da barra de progresso */
.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile p progress::-webkit-progress-value {
    background-color: #03CEA4;
}

.telaMobile .cardMobile .meioCardMobile .rightMobile {
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 1vh;

}

.telaMobile .cardMobile .meioCardMobile .rightMobile .ultimoProfMobile {
    background-color: #003895;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10%;
    margin-bottom: 30px;
    border-radius: 33px;

    background-size: 10rem;
    background-position: right bottom;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    height: 48vh;
    width: 38vw;
    font-size: 2.2vh;
}

.rightMobile {
    margin-right: 8%;
}

.telaMobile .cardMobile .meioCardMobile .rightMobile .ultimoProfMobile .topoMobile {
    width: 80%;
    height: max-content;
    text-align: center;
    font-size: 2.5vh;
}

.telaMobile .cardMobile .meioCardMobile .rightMobile .ultimoProfMobile .imagemMobile {
    width: 30vw;
    height: 30vh;
}

.telaMobile .cardMobile .meioCardMobile .rightMobile .ultimoProfMobile {
    background-image: url("../../../../public/pag_inicial_paciente/Vector\ 2.svg");
    background-size: 90%;
    background-position: right bottom;
}

.telaMobile .cardMobile .meioCardMobile .rightMobile .ultimoProfMobile p {
    margin-bottom: 40%;
}

.telaMobile .cardMobile .meioCardMobile .rightMobile .ultimoProfMobile .topoCardMobile p {
    margin-bottom: 0%;
}

.ultimoCardMobile {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    margin-left: 10%;
    height: auto;
    gap: 1rem;
}

.telaMobile .cardMobile .ultimoCardMobile .equipeSaudeMobile {
    cursor: pointer;
    background-color: #F04379;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: right top;

    padding: 4vh;
    border-radius: 33px;
    margin-right: 2%;

    height: 24vh;
    width: 38vw;
}

.telaMobile .cardMobile .ultimoCardMobile .anexoExameMobile {
    background-color: #7C51F5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right top;

    padding: 2.9vh;
    border-radius: 33px;
    margin-bottom: 0%;

    height: 24vh;
    width: 38vw;
}

.telaMobile .cardMobile .ultimoCardMobile .anexoExameMobile:hover {
    cursor: pointer;
}

.telaMobile .cardMobile .ultimoCardMobile .anexoExameMobile p {
    font-size: 2.3vh;
    margin-top: 8vh;
}


.telaMobile .cardMobile .ultimoCardMobile .perfilMobile {
    background-color: #3494F3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: right bottom;

    padding-left: 5%;
    border-radius: 33px;
    padding-top: 10%;
    font-size: 5vh;
}

.telaMobile .cardMobile .ultimoCardMobile .perfilMobile:hover {
    cursor: pointer;
}

.telaMobile .cardMobile .ultimoCardMobile .perfilMobile p span {
    text-align: end;
    margin-left: 30%;
    font-size: 1vh;
}

.telaMobile .cardMobile .ultimoCardMobile .perfilMobile p progress {
    appearance: none;
}

.telaMobile .cardMobile .ultimoCardMobile .perfilMobile p progress::-webkit-progress-bar {
    background-color: lightgray;
}

/* Estilizando a parte preenchida da barra de progresso */
.telaMobile .cardMobile .ultimoCardMobile .perfilMobile p progress::-webkit-progress-value {
    background-color: #FFC300;
}

.telaMobile .cardMobile .ultimoCardMobile .perfilMobile p .progresscontainerMobile {
    width: 70%;
    height: 10px;
    /* Defina a altura da barra */
    background-color: #FFC300;
    border-radius: 6px;
    /* Arredonde as bordas do contêiner */
    overflow: hidden;
}

.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile {
    background-color: #FF454F;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: right top;
    padding-left: 5%;
    border-radius: 33px;

    height: 18vh;
    width: 80vw;
}

.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile:hover {
    cursor: pointer;
}

.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile p span {
    margin-left: 10vw;
    font-size: 2.5vh;
}

.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile p .progresscontainerMobile {
    width: 70vw;
    height: 12px;
    background-color: #03CEA4;
    border-radius: 6px;
    overflow: hidden;
}

.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile p progress {
    height: 100%;
}

.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile p progress::-webkit-progress-bar {
    background-color: lightgray;
}

/* Estilizando a parte preenchida da barra de progresso */
.telaMobile .cardMobile .ultimoCardMobile .consultasCompletasMobile p progress::-webkit-progress-value {
    background-color: #03CEA4;
}

.telaMobile .cardMobile .ultimoCardMobile .novaConsultaMobile {
    background-color: #5C80FF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: right top;

    padding: 2.5vh;
    border-radius: 33px;
    display: flex;
    align-items: center;

    font-size: 2.5vh;
    height: 15vh;
    width: 80vw;
}


.telaMobile .cardMobile .ultimoCardMobile .numeroConsultasMobile {
    cursor: pointer;
    background-color: #FFC400;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: right bottom;

    padding: 10%;
    border-radius: 33px;

    height: 30vh;
    width: 80vw;

    margin-bottom: 50vh;
}

.telaMobile .cardMobile .ultimoCardMobile .numeroConsultasMobile p {
    padding: 0vh;
    font-size: 3.5vh;
}