.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 5vh 0;
  }
  
.logo {
  padding-left: 3rem;
}

.navbarMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1vh 6.5vw;
  width: 100vw;
  height: auto;

  position: sticky;
  top: 0;
  z-index: 1;
  transition: .4s linear;
  
  background-color: #FFF;
  border-bottom-left-radius: 6vw;
  border-bottom-right-radius: 6vw;
  
  font-family: 'Mulish', sans-serif;
  font-style: normal;
}

.hudlogoMobile{
  display: flex;
  flex-direction: column;
  padding: 4vw;
  justify-content: center;
  align-items: center;
  gap: 2.78vw;
  width: auto;
  height: auto;
}

.logoMobile{
  width: 55vw;
  height: auto;
}

.sideBarMobile {
  display: flex;
  width: 100vw;
  height: auto;
  flex-direction: column;
  align-items: center;
  gap: 6vh;
  text-align: center;
  background-color: #F3F3F3;
  padding-bottom: 6.67vw;
}

.sideBarMenuMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6vh;
}

.sideBarLogoutMenuMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
}

.sideBarLogoutMobile{
  display: flex;
  width: 52vw;
  height: 14vw;
  padding: 2.78vw;
  justify-content: center;
  align-items: center;
  gap: 3vw;
  background: #FFF;
  box-shadow: 0vw 0vw 1.11vw 0vw rgba(0, 0, 0, 0.25);
  border-radius: 2.2vw;
}

.sideBarProfileMobile{
  display: flex;
  width: 52vw;
  height: 14vw;
  padding: 2.8vw;
  justify-content: center;
  align-items: center;
  gap: 1.22vh;
  background: var(--primary-primary-01, #EBB70C);
  box-shadow: 0vw 0vw 1.11vw 0vw rgba(0, 0, 0, 0.25);
  border-radius: 2.2vw;
}