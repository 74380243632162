.pagina {
  width: 100%;
  height: 100%;
  margin: 3%;
}

.pagina .voltar {
  margin-top: 1rem;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.pagina .barraLinks {
  margin-top: 2rem;
  display: flex;
}

.pagina .barraLinks .linhaLinks {
  width: 2px;
  height: 30px;
  margin: 0px 20px 0px 20px;
  background-color: black;
}

.pagina .barraLinks .link {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;

  margin: 0;
  border-bottom: #ffff 3px solid;
}

.link:hover {
  border-bottom: #ebb70c 3px solid;
}

.pagina .conteudo {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.pagina .conteudo .dados {
  width: 100%;
}

.pagina .conteudo .dados .linha {
  display: flex;
  justify-content: space-between;
}

.pagina .conteudo .dados .linha2 {
  width: 66.5%;
  display: flex;
  justify-content: flex-start;
}

.pagina .conteudo .dados .botao {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0 1rem 0;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
.headerTab {
  background-color: #003895;
  color: #ffffff;
}