.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  position: top;
  width: auto;
  height: 10vh;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.item:hover u{
  background-color: #EBB70C;
}