.DetalhesConsulta {
    width: 100%;
    height: calc(100vh - 4rem);
    display: flex;

}

a {
    border-bottom: #fff 3px solid;
}

a:hover {
    border-bottom: #ebb70c 3px solid;
}

.menu {
    /*Menu em coluna na esquerda da página*/

    width: 20%;
    height: 100%;
    padding-top: 5%;

    background: #FFFFFF;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left bottom;

    backdrop-filter: blur(18px);
    display: flex;
    flex-direction: column;

    border-radius: 0px;
}

.menu .linkMenu {
    display: flex;
    align-items: flex-start;
    margin-left: 30%;
    margin-top: 10%;
    cursor: pointer;
}

.menu .textoMenu {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
}

.menu .linkMenu .imgMenu {
    width: 10px;
    height: 10px;
}

.tela {
    width: 100%;
    background: linear-gradient(57.12deg, rgba(0, 56, 149, 0.9) 25.84%, rgba(235, 183, 12, 0.9) 95.25%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.tela .card {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 33px;
    box-sizing: border-box;
    font-family: 'Mulish';
    font-style: normal;
    overflow: auto
    
}

.tela .card .topoCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0px 0px 30px;
}

.tela .card .topoCard .esquerda .voltar {
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
}

.tela .card .topoCard .esquerda .voltar a {
    text-decoration: none;
    color: #000000;
}

.tela .card .topoCard .esquerda .title {
    margin: 4vh 0px;
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
}

.tela .card .topoCard .direita {
    color: rgb(3, 6, 5);
}

.tela .card .layoutBar {
    display: flex;
    align-items: flex-start;
    padding-left: 30px;
}


.tela .card button {
    appearance: none;
    border: none;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}

.tela .card button:hover{
    text-decoration: none;
}

.inativo:hover {
    color: #ebb70c;
    text-decoration: underline;
}

.ativo {
    border-bottom: #ebb70c 3px solid;
}

.ativoMobile {
    align-self: stretch;
    border-radius: 0.5vw;
    border-bottom: 1.1vw solid;
    border-color: var(--secondary-secondary-01, #EBB70C);
    display: flex;
}

.tela .card .topoCard .direita {
    background-color:  #e5e9ff;
    border-radius: 20px;
    padding: 10px 10px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: 700;
}

.tela .card .topoCard .direita .medico {
    color:#000;
    margin-top: 10px;
    font-size: 24px;
}

.tela .card .topoCard .direita .esp {
    color:#003895;
    margin-top: 10px;
    font-size: 20px;
}



/************* CSS da parte 3****************/


.linhaCuidado{
    display: flex;
    margin: 2vh 0px 0px 27px;
    font-weight: 800;
    font-size: 26px;
}

.linhaCuidado .textoCuidado{
    padding-left: 1vh;
    color: rgb(14, 14, 161);
}


.textoProgresso{
    margin-left: 27px;
    margin-top: 2vh;
    display: flex;
    font-size:22px;
    margin-bottom: 1vh;

    text-decoration: underline #ebb70c 3px solid;

    /*Padronização da letra*/
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
}


.consultas{
    display: flex;
    flex-direction: column;
    margin: 0px 27px;
    /*Padronização da letra*/
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
}

.consultas .consulta{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.5);
    padding:1.5vh 1vh 0vh 1vh;
    margin-top: 15px;
} 

.consultas .consulta .informacoesConsulta {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.consultas .consulta .consultaVerDetalhes {
    text-decoration: underline rgb(11, 11, 188) 1.5px;
    color: rgb(11, 11, 188);

    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;

    cursor: pointer;

    background-color: #FFFFFF;
    border: none;
}

.consultas .consulta .consultaVerDetalhes:hover{
    text-decoration: underline rgb(6, 6, 80) 1.5px;
    color: rgb(6, 6, 80);
}


.barra{
    padding: 0.8vh;
    font-size: 20px;
    font-weight:500;
}

.barraAmarela{
    padding: 0px 15px;
    margin-top: 0px;
    font-size: 20px;
    font-weight:500;
    color: #ebb70c;
}



/*****************************/


/************** CSS da parte 2 ***************/

.listaAnexos {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 27px;
    font-size: 15px;
}

.anexo{
    display: flex;
    align-items: center;
    width: 370px;
    height: 150px;
}

.anexoInformacao{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 6%;
}


/*****************************/

/*********** CSS da parte 1 ****************/

.tela .card .informacoes {
    margin: 30px 0px 0px 30px;
    font-size: 20px;
    font-weight: 700;
    
    font-size: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
}

.tela .card .informacoes .item{
    margin-bottom: 20px;
    font-size:20px;
    font-weight: 700;
    border-radius: 5px;
    background-color: rgb(251, 253, 255);
}

.tela .card .informacoes .item p{
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;
}
.linha {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 30px 0px 25px;
}

/***********************************************/


/************* CSS MOBILE *********************/

.paginaMobile{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2vh;
    padding: 6.67vw 6.67vw 6.67vw 5.77vw;     
}

.linkVoltarMobile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.paginaContentBackgroundMobile {
    display: flex;
    width: 86.7vw;
    padding: 8.88vw 6.67vw;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
    border-radius: 6.67vw;
    background: #E5E9FF;
}

.paginaContentMobile {
    display: flex;
    width: 73.326vw;
    padding: 2vw;
    flex-direction: column;
    align-items: center;
    gap: 6.67vw;
}

.detalhesConsultaMobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh; 
    color: #000;
    font-family: Mulish;
    font-size: 6.67vw;
    font-style: normal;
    font-weight: 800;
    line-height: 2vh;
}

.guiaConsultasMobile {  
    display: flex;
    align-items: center;
    gap: 5vw; 
    width: 80vw;
    height: 7vh;
    overflow-y: visible;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* Internet Explorer, Microsoft Edge*/
    scrollbar-width: none; /* Firefox */
    white-space: nowrap;
}

.guiaConsultasMobile::-webkit-scrollbar{
    display: none; /* Chrome, Safari, Opera */
}

.textoConsultasMobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000;
    font-family: Mulish;
    font-size: 5vw;
    font-style: normal;
    font-weight: 800;
    line-height: 2vh;
}

.underlineConsultasMobile{
    height: 1.11vw;
    align-self: stretch;
    border-radius: 3.33vw;
    background: var(--secondary-secondary-01, #EBB70C);
}

.barraConsultasMobile{
    width: 0.8vw;
    height: 7vw;
    flex-shrink: 0;
    border-radius: 2.22vw;
    background: #000;
}

.linhaCuidadoMobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4vh;
    align-self: stretch;
    color: black;
    text-align: center;
    font-family: Mulish;
    font-size: 6.67vw;
    font-style: normal;
    font-weight: 800;
    line-height: 2.5vh;
}

.textoCuidadoMobile{
    color: var(--primary-primary-01, #003895);
    font-family: Mulish;
    font-size: 6.67vw;
    font-style: normal;
    font-weight: 800;
    line-height: 2.5vh;
}

.linhaConsultasMobile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4vh;
    align-self: stretch;
}

.consultaMobile{
    display: flex;
    width: 73.3vw;
    padding: 3.3vw 4.4vw;
    gap: 1vh;
    border-radius: 2.22vw;
    background: #FFF;
    box-shadow: 0 0 3vw 0 rgba(0, 0, 0, 0.25);
    margin: 5vw 0;
}

.informacoesConsultaMobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
    flex: 1 0 0;
    align-self: stretch;
}

.consultaDoutorMobile {
    align-self: stretch;
    color: #000;
    font-family: Mulish;
    font-size: 4.44vw;
    font-style: normal;
    font-weight: 800;
    line-height: 3vh; 
}

.consultaDataStatusMobile{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.8vh;
}

.consultaDataMobile{
    color: var(--primary-primary-01, #003895);
    font-family: Mulish;
    font-size: 4.44vw;
    font-style: normal;
    font-weight: 800;
    line-height: 2vh; 
}

.barraMobile{
    width: 0.55vw;
    height: 8vw;
    border-radius: 2.22vw;
    background: var(--neutral-900, #393939);
}

.consultaStatusMobile{
    font-family: Mulish;
    font-size: 4.44vw;
    font-style: normal;
    font-weight: 800;
    line-height: 2vh;
}

.consultaVerDetalhesMobile {
    color: var(--primary-primary-01, #003895);
    font-family: Mulish;
    font-size: 4.44vw;
    font-style: normal;
    font-weight: 800;
    line-height: 2vh; 
    text-decoration-line: underline;
}

.infoMobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10vw;
    align-self: stretch;
}

.infoMedicoMobile{
    display: flex;
    padding: 3.33vw;
    justify-content: center;
    align-items: center;
    gap: 1vh;
    align-self: stretch;
    border-radius: 3.33vw;
    background: #CDD3FF;
}

.infoMedicoTextoMobile{
    display: flex;
    padding: 2.77vw;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.77vw;
}

.infoMedicoNomeMobile{
    color: #000;
    font-family: Mulish;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 800;
    line-height: 4.5vw;
}

.infoConsultaMobile{
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3vh;
    align-self: stretch;
}

.infoDadosConsultaMobile{
    display: flex;
    height: 5vh;
    flex-direction: column;
    align-items: flex-start;
    gap: 1vh;
    align-self: stretch;
    border-radius: 3.33vw;
}

.infoDadosConsultaTitleMobile{
    display: flex;
    align-items: center;
    gap: 1vh;
    flex: 1 0 0;
}

.infoDadosConsultaTitleTextoMobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    font-family: Mulish;
    font-size: 5.5vw;
    font-style: normal;
    font-weight: 800;
    line-height: 2vh;
}

.infoDadosConsultaBodyMobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    color: #000;
    font-family: Mulish;
    font-size: 4.44vw;
    font-style: normal;
    font-weight: 400;
    line-height: 2vh;
}

.listaAnexosMobile {
    display: flex;
    width: 83vw;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 6.67vw;
}

.anexoMobile{
    display: flex;
    padding: 3.33vw;
    align-items: center;
    gap: 6.67vw;
    align-self: stretch;
    border-radius: 3.33vw;
    background: var(--primary-50, #E5E9FF);
}

.anexoInformacaoMobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.5vh;
}

.anexoTextoMobile{
    display: flex;
    color: #000;
    font-family: Mulish;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: 4vw;
    word-break: break-word;
}