*{  
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  height: 100%;
  min-height: 100vh;
  width: 100%;
}

