.pagina {
    width: 100%;
    height:100%;
    margin:2%;
}

.pagina .voltar {
    margin: 50px 0px 0px 0px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.pagina .barraLinks {
    margin: 50px 0px 40px 0px;
    display:flex;
}

.pagina .barraLinks .linhaLinks {
    width:2px;
    height:30px;
    margin: 0px 20px 0px 20px;
    background-color: black;
}

.pagina .barraLinks .link {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

    margin: 0px 0px 0px 0px;
}

.link{
    border-bottom: white 3px solid;
}
.link:hover{
    border-bottom: #EBB70C 3px solid;
}

.pagina .conteudo {
    width: 100%;
    height:fit-content;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pagina .conteudo .dados {
    width:100%;
}

.pagina .conteudo .dados .linha{
    display:flex;
    justify-content: space-around;
}

.pagina .conteudo .dados .ultimaLinha{
    justify-content: space-between;
    display:flex;
    justify-content: space-around;
    width:33.5%;
}

.pagina .conteudo .dados .botao {
    position:absolute;
    right:0px;
    margin:20px 30px 20px 30px;
}

table,
th,
td {
  border-bottom: 2px solid #003895;
  border-collapse: collapse;
  height: 60px;
  text-align: center;
}

td:first-child {
  width: 10%;
}

td:nth-child(2) {
  width: 30%;
}

td:nth-child(3) {
  width: 20%;
}

td:last-child {
  width: 20%;
  padding: 0;
} 

.headerTab {
  background-color: #003895;
  color: #ffffff;
}