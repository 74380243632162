@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&family=Roboto:wght@100;700&display=swap');


*{
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url(../../../public/cornerDetail.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 70vh;
    width: 100vw;
}

.contentCard{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 100vh;
    max-height: 73vh;
    width: 70%;
    margin-bottom: 10vh;

    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 24px;

    max-width: 1100px;
}

.textFieldSection{
    display: flex;
    flex-direction: column;

    align-items: center;
    
    width: 50%;
    height: 100%;
    padding: 3rem 5rem;

    background: #E5E9FF;;
    border-radius: 24px 0px 0px 24px;
}

.textFieldSectionTop h2{
    font-style: normal;
    font-size: 2.2em;
    /* MUDAR ISSOOOOO
    
    
    
    ************************
    
    */
    line-height: 30px;
    margin-bottom: 40px;
}

#fullwidth{
    padding-top: 100px;
}
.textField{
    width: 100%;
    background-color: #CDD3FF;
    font-style: bold;
    border: none;
    border-bottom: 2px solid #606060;
    border-radius: 8px;
}

.btn{
    width: 100%;

    background: #003895;
    border-radius: 12px;
    border: none;

    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #FFFFFF;

    padding: 13px;
    margin-top: 20px;
    margin-bottom: 70px;

    cursor: pointer;
}


.illustration {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.illustration img {
    width: 90%;
    height: 90%;
    padding: 15px;
}

@media screen and (min-width: 1000px) and (max-width: 1440px){
    
}



.contentMobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url(../../../public/cornerDetail.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 70vh;
    width: 100vw;
    
    border-radius: 2vh;
}

.contentCardMobile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 100vh;
    max-height: 73vh;
    width: 80vw;
    margin-bottom: 10vh;

    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 2vh;
}

.textFieldSectionMobile{
    display: flex;
    flex-direction: column;

    align-items: center;
    
    width: 100vw;
    height: 100%;
    padding: 3vh 5vw;

    background: #E5E9FF;;
    border-radius: 2vh;
}

.textFieldSectionTopMobile h2{
    font-style: normal;
    font-size: 2.2em;
    line-height: 5vh;
    margin-top: 10vh;
    margin-bottom: 8vh;
}

#fullwidth{
    padding-top: 18vh;
}
.textFieldMobile{
    width: 100vw;
    background-color: #CDD3FF;
    font-style: bold;
    border-radius: 1vh;
}

.btnMobile{
    width: 70vw;

    background: #003895;
    border-radius: 1vh;
    border: none;

    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    color: #FFFFFF;

    padding: 13px;
    margin-top: 5vh;
    margin-bottom: 70px;

    cursor: pointer;

    justify-content: center;
}


.illustrationMobile {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

